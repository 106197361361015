<template>
    <b-row>
        <b-col cols="12">
            <search-view :click-search="getProjectManager"/>
        </b-col>


        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="ml-1 mb-2">
                        <b-row>
                            <b-col cols="12" md="9"
                                   class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            >
                                <label>Mostrar</label>
                                <v-select
                                    v-model="pageLength"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="['3','5','10','20']"
                                    :clearable="false"
                                    class="per-page-selector d-inline-block mx-50"
                                    @input="changeLength"
                                />
                                <b-button variant="primary" @click="goToCreate()">
                                    Añadir nuevo projecto
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table
                        ref="refUserListTable"
                        class="position-relative"
                        :items="projects_managers"
                        responsive
                        striped
                        sticky-header
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="No hay registros"
                    >

                        <template #cell(actions)="data" class="col-sm-2">
                            <router-link :to="{ name: 'auxiliar2_project_manager_edit_vhe', params: { id: data.item.id} }">
                                <feather-icon
                                    :id="`invoice-row-${data.item.id}-send-icon`"
                                    icon="EditIcon"
                                    class="cursor-pointer"
                                    size="16"
                                />

                            </router-link>

                            <feather-icon
                                :id="`invoice-row-${data.item.id}-preview-icon`"
                                icon="TrashIcon"
                                size="16"
                                class="cursor-pointer ml-1 text-red"
                                @click="deleteProjectManager(data.item.id)"
                            />

                        </template>
                    </b-table>

                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                            >
                                <span class="text-muted">Mostrando {{ pageLength }} de {{ totalRecords }} projectos</span>
                            </b-col>

                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    :value="1"
                                    :total-rows="totalRecords"
                                    :per-page="pageLength"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @input="changePage"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BButton, BTooltip, BPagination, BOverlay, BBadge} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {VHE_PROJECT_MANAGER_CREATE} from "@/router/web/constants"
import helpers from "@/resources/helpers/helpers"
import SearchView from "@/views/vhe/auxiliars2_project_manager/list/components/searchView.vue"
import helper from "@/resources/helpers/helpers"

export default {
    name: "ListView",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BButton,
        BPagination,
        BOverlay,
        SearchView,
        vSelect,

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            columns: [
                {key: 'name', label: 'Gestor Responsable', sortable: false},
                {key: 'actions', label: 'Acciones', sortable: false},
            ],
            showLoading: false,
            helpers
        }
    },
    computed: {
        ...mapState('auxiliaries2ProjectManagerVhe', ['projects_managers', 'search']),
    },
    created() {
        this.getProjectManager()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        formatDate(date) {
            const dateFormated = new Date(date)

            return dateFormated.toLocaleDateString("es-ES")
        },
        getProjectManager() {
            this.showLoading = true

            this.$store.dispatch('auxiliaries2ProjectManagerVhe/searchProjectManager', {
                per_page: this.pageLength,
                page: this.page,
                filter: this.search,
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records

            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
        deleteProjectManager(id) {
            Swal.fire({
                title: "Estás seguro que quieres eliminar a este proyecto?",
                text: "Una vez borrado no se podra recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, borrar!",
                cancelButtonText: "Cancelar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('auxiliaries2ProjectManagerVhe/deleteProjectManager', {id: id})
                    .then(response => {
                        this.showLoading = false
                        helper.showToast('Proyecto eliminado correctamente!', 'CheckIcon', 'success')
                        this.getProjectManager()
                    })
                    .catch(error => {
                        this.showLoading = false
                        helper.showToast('Error al eliminar el proyecto', 'AlertOctagonIcon', 'danger')
                        console.log(error)
                    })
                }
            })
        },
        changePage(page) {
            this.page = page
            this.getProjectManager()
        },
        changeLength(length) {
            this.length = length
            this.getProjectManager()
        },
        searchUser() {
            this.getProjectManager()
        },
        goToCreate() {
            this.$router.push(VHE_PROJECT_MANAGER_CREATE)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>